<template>
  <div id="app">
    <div class="index">
      <Header/>
      <router-view></router-view>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import axios from "axios";

export default {
  name: 'App',
  data(){
    return {
      apiUrl: axios.defaults.baseURL  
    }
  },
  components: {
    Header,
    Footer,
  },

}
</script>

<style>
body { background:#fff; margin:0; padding:0; font-size:14px; font-family: 'microsoft yahei'; }
a { text-decoration: none; color:#555; }
#app {
  font-family: 'microsoft yahei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  min-width:1136px;
  height:100%;
}
ul, li { list-style-type: none; margin:0; padding:0;}
img { vertical-align: middlee; margin:0; border:none; }
.index {
  height: 100%;
}
h1,h2,h3,h4,h5,h6 { margin:0; padding:0; }
.f12 { font-size:12px;}
.ant-breadcrumb-link a.home { color:#555; }
.ant-breadcrumb { margin:20px 0; }
.ant-breadcrumb { line-height:1.5; color:rgba(0,0,0,0.45); font-size:14px; }
.ant-breadcrumb-link { color:#555; }
.ant-breadcrumb-separator { margin:0 8px; color:rgba(0,0,0  ,0.45)}
</style>