import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'

//导入路由模块
import VueRouter from 'vue-router'
import router from '@/router'

Vue.config.devtools = true
Vue.config.productionTip = false
Vue.prototype.$axios = axios
axios.defaults.baseURL = "https://api.nssygl.com/"
axios.defaults.assetUrl = "https://robot.cnit365.cn/"

/**全局注册使用swiper */
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

new Vue({
  store,
  axios,
  render: h => h(App),
  router
}).$mount('#app')

