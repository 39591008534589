import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: '仁孝福寿'
        }
    },
    {
        path: '/cemetery',
        name: 'cemetery',
        component: () => import( '@/components/Cemetery.vue' )                              //墓园列表组件
    },
    {
        path: '/cemetery/index',
        name: 'CemeteryIndex',
        component: () => import( '@/components/Cemetery/index.vue'),                         //墓园首页
        meta: {
            title: '',
            keepAlive: true
        }
    },
    {
        path: '/cemetery/cemeteryDetail',
        name: 'CemeteryDetail',
        component: () => import( '@/components/Cemetery/CemeteryDetail.vue' )              //墓园详情组件
    },
    {
        path: '/cemetery/navigate',
        name: 'Navigate',
        component: () => import( '@/components/Cemetery/Navigate.vue' )                         //一键定位
    },
    {
        path: '/funeral',
        name: 'funeral',
        component: () => import( '@/components/Funeral.vue' )
    },
    {
        path: '/funeral/funeralDetail',
        name: 'FuneralDetail',
        component: () => import( '@/components/funeral/FuneralDetail.vue' )
    },
    {
        path: '/article/article',
        name: 'article',
        component: () => import( '@/components/article/article.vue' )
    },
    {
        path: '/article/articlePage',
        name: 'articlePage',
        component: () => import( '@/components/article/articlePage.vue' )
    },
    {
        path: '/article/list',
        name: 'list',
        component: () => import( '@/components/article/list.vue' )
    },
    {
        path: '/appoint',
        name: 'appoint',
        component: () => import( '@/components/Appoint.vue' )
    },
    {
        path: '/appoint/sweepTomb',
        name: 'sweepTomb',
        component: () => import( '@/components/appoint/sweepTomb.vue' )
    },
    {
        path: '/pc/privacy',
        name: 'privacy',
        component: () => import( '@/components/pc/Privacy.vue' )
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    if(to.meta.title){
        document.title = to.meta.title
    }else{
        document.title = '仁孝福寿'
    }
    next();
})
// console.log(router);

export default router;


